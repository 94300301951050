import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unit',
  standalone: true
})
export class UnitPipe implements PipeTransform {

  transform(value: unknown, unit: string): string {
    if (this.isCurrency(unit)) {
      return `${unit}${value}`;
    }

    if (this.isSymbol(unit)) {
      return `${value}${unit}`;
    }

    return `${value} ${unit}`;
  }

  private isCurrency(unit: string) {
    const currencies = ['$', '₦'];
    return currencies.includes(unit);
  }

  private isSymbol(unit: string) {
    const symbols = ['%'];
    return symbols.includes(unit);
  }
}
