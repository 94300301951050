<mat-card class="blank-card mb-5">
  <mat-card-content>
    <div class="row">
      <div class="col-xl-6">
        <div class="blank-card__img">
          <img class="" [src]="imageSrc" alt="">
        </div>

      </div>
      <div class="col-xl-6">
        <div class="blank-card__texts">
          <p>You currently have no {{cardText}}. Click the Create button to add one</p>
          <button type="button" (click)="createNew()" mat-raised-button color="primary">Create {{btnText}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
