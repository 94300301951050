import { Component, Input } from '@angular/core';

@Component({
  selector: 'olla-loading-spinner',
  standalone: true,
  template: `
    @if(loading) {
      <div class="loading-shade">
        <div class="spinner-box">
          <div class="three-quarter-spinner"></div>
        </div>
      </div>
    }
  `,
  styles: [`

  .loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .three-quarter-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #4e73df;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
    -webkit-animation: spin .5s linear 0s infinite;
  }


  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to{
      transform: rotate(359deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      transform: rotate(0);
    }
    to{
      transform: rotate(359deg);
    }
  }

  `]
})
export class LoadingSpinnerComponent {
  @Input() loading!: boolean | null;
}
