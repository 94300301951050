import { CommonModule, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ErrorResponse } from '@app/models/error.model';
import { ClientService } from '@modules/sales/services/client.service';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedModule } from '@shared/shared.module';

@UntilDestroy()
@Component({
  selector: 'olla-create-client-contact',
  template: `
    <form
      class="modal-wrapper"
      [formGroup]="contactForm"
      (ngSubmit)="addContact()"
    >
      <div class="row">
        <div class="col-xl">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Contact Name</mat-label>
            <input
              formControlName="name"
              required
              matInput
              placeholder="Name"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Phone</mat-label>
            <input
              formControlName="phone"
              required
              matInput
              placeholder="Phone"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input
              formControlName="email"
              required
              matInput
              placeholder="Email"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Position</mat-label>
            <input
              formControlName="position"
              required
              matInput
              placeholder="Position"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex">
        <button
          class="btn btn-block btn-outline-primary"
          [disabled]="!contactForm.valid"
        >
          <span *ngIf="!loading">Create</span>
          <div
            *ngIf="loading"
            class="spinner-border text-primary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </form>
  `,
  styles: [],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    NgIf,
    MatInput
  ]
})
export class ClientContactFormComponent implements OnInit {
  loading = false;
  contactForm!: FormGroup<ContactForm>;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private toast: HotToastService,
    public dialogRef: MatDialogRef<ClientContactFormComponent>,
    @Inject(MAT_DIALOG_DATA) public info: { clientId: number }
  ) {}

  ngOnInit(): void {
    this.contactForm = this.fb.nonNullable.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      position: ['', Validators.required],
      clientId: [this.info.clientId],
    });
  }

  addContact(): void {
    this.loading = true;
    const request = this.contactForm.getRawValue();
    this.clientService
      .createClientContact(request)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (resp) => {
          this.toast.success('Contact Added');
          this.dialogRef.close(resp.entityId);
        },
        error: (err: ErrorResponse) => {
          this.loading = false;
          this.toast.error(err.message);
        },
      });
  }
}

interface ContactForm {
  name: FormControl<string>;
  phone: FormControl<string>;
  email: FormControl<string>;
  position: FormControl<string>;
  clientId: FormControl<number>;
}
