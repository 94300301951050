import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ErrorResponse } from '@app/models/error.model';
import { ClientService } from '@modules/sales/services/client.service';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'olla-create-client',
  template: `
    <form
      class="modal-wrapper"
      [formGroup]="clientForm"
      (ngSubmit)="addClient()"
    >
      <div class="row">
        <div class="col-xl">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Client</mat-label>
            <input
              formControlName="name"
              required
              matInput
              placeholder="Client"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl">
          <mat-checkbox formControlName="prospective">Prospective</mat-checkbox>
        </div>
      </div>
      <div class="d-flex">
        <button
          class="btn btn-block btn-outline-primary"
          [disabled]="!clientForm.valid"
        >
          <span *ngIf="!loading">Create</span>
          <div
            *ngIf="loading"
            class="spinner-border text-primary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </form>
  `,
  styles: [],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    NgIf,
    MatInputModule,
    MatCheckboxModule
  ]
})
export class ClientFormComponent implements OnInit {
  loading = false;
  clientForm!: FormGroup<ClientForm>;
  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<ClientFormComponent>,
    private toast: HotToastService
  ) {}

  ngOnInit(): void {
    this.clientForm = this.fb.nonNullable.group({
      name: ['', Validators.required],
      prospective: [false],
    });
  }

  addClient(): void {
    this.loading = true;
    const request = this.clientForm.getRawValue();
    this.clientService
      .createClient(request)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (resp) => {
          this.toast.success('Client Added');
          this.dialogRef.close(resp.entityId);
        },
        error: (err: ErrorResponse) => {
          this.loading = false;
          this.toast.error(err.message);
        },
      });
  }
}

interface ClientForm {
  name: FormControl<string>;
  prospective: FormControl<boolean>;
}
