import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'position', standalone: true })
export class PositionPipe implements PipeTransform {

  transform(value: number, pageIndex: number, pageSize: number): number {
    const position = pageIndex * pageSize + value + 1;
    return position;
  }
}
