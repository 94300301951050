import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShortNamePipe } from '@shared/pipes/short-name.pipe';

@Component({
  selector: 'olla-avatar',
  standalone: true,
  imports: [ShortNamePipe, MatTooltipModule],
  template: `
    <div [matTooltip]="name" [matTooltipDisabled]="!showTooltip"  class="avatar d-flex justify-content-center align-items-center rounded-circle">


      @if(imageUrl) {
        <img [src]="imageUrl" [alt]="name" class="h-100 w-100 rounded-circle"/>
      } @else {
        <span class="m-0 fw-500 fs-14 txt-black">{{name | shortName}}</span>
      }
    </div>
  `,
  styles: `
    .avatar {
      width: 32px;
      height: 32px;
      background-color: #f2f4f7;
      border: 1px solid #383f571a;
    }
  `
})
export class AvatarComponent {

  @Input({ required: true }) name = ''
  @Input() imageUrl = ''
  @Input() showTooltip = false
}
