import { NgClass, NgStyle } from '@angular/common';
import { Component, Input, computed, signal } from '@angular/core';

@Component({
  selector: 'olla-progress-bar',
  standalone: true,
  imports: [NgClass, NgStyle],
  template: `
    <div class="progress rounded-pill" [ngClass]="{'slim': slim}">
      <div
        title="progressbar"
        class="progress-bar"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{'width': width(), 'background-color': color() }"
      >
      </div>
    </div>
  `,
  styles: `
  .slim {
    height : 0.25rem;
  }
  `
})
export class ProgressBarComponent {

 @Input({ required: true }) set percentage(value: number) {
  this.mainPercentage.set(value);
 }
 @Input() slim = false;

 private readonly mainPercentage = signal(0)
 width = computed(() => `${this.mainPercentage()}%`);
 color = computed(() => {
  const value = this.mainPercentage();

  if (value < 25) {
    return '#ff0000';
  }
  if (value >= 25 && value < 50) {
    return '#ffa500';
  }
  if (value >= 50 && value < 75) {
    return '#4895ef';
  }
  if (value >= 75 && value < 100) {
    return '#17a2b8';
  }

  return '#008000';
 });
}
