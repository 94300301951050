import { Injectable } from '@angular/core';
import {
  ClientResponse, ClientContactResponse,
  ClientContactCreateRequest, ClientCreateRequest
} from '@modules/sales/models/client.model';
import { MainResponse, OptionResponse, PagedList } from '@app/models/response.model';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private api: ApiService) {}

  createClient(request: ClientCreateRequest): Observable<MainResponse> {
    return this.api.post("clients/add", request);
  }

  createClientContact(request: ClientContactCreateRequest): Observable<MainResponse> {
    return this.api.post("clients/contacts/add", request);
  }

  getPagedClient(pageNo: number, pageSize: number): Observable<PagedList<ClientResponse[]>> {
    return this.api.getPaged("clients/paged", pageNo, pageSize);
  }

  getClients(): Observable<OptionResponse[]> {
    return this.api.get("clients/all");
  }

  getClient(clientId: number): Observable<ClientResponse> {
    return this.api.get(`clients/${clientId}`);
  }

  getClientContacts(clientId: number): Observable<OptionResponse[]> {
    return this.api.get(`clients/contacts/all?clientId=${clientId}`);
  }

  getPagedClientContact(clientId: number, pageNo: number, pageSize: number): Observable<PagedList<ClientContactResponse[]>> {
    return this.api.getPaged(`clients/contacts/paged`, pageNo, pageSize, `clientId=${clientId}`);
  }
}
