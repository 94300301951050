import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, parseISO } from 'date-fns';

@Pipe({
    name: 'dateAgo',
    standalone: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: string): string {
      if (value) {
        const result = formatDistanceToNow( parseISO(value), { addSuffix: true })
        return result;
      }
      return value;
  }
}
